import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { motion, useInView } from "framer-motion";
import { useLoadOnVisible, useIsMobile } from "../../hooks";

import milaquiteVideo from "assets/images/landingAir/video-content/quiet-mode.webm";
import housekeepingVideo from "assets/images/landingAir/video-content/housekeeping-service.webm";
import whitenoiseMode from "assets/images/landingAir/video-content/whitenoise-mode.webm";
import whitenoiseModeMp4 from "assets/images/landingAir/video-content/whitenoise-mode.mp4";
import milaquiteVideoMp4 from "assets/images/landingAir/video-content/quiet-mode.mp4";
import housekeepingVideoMp4 from "assets/images/landingAir/video-content/housekeeping-service.mp4";

const videoData = [
  {
    src: milaquiteVideo,
    srcMp4: milaquiteVideoMp4,
    text: {
      paragraph: (
        <>
          Mila quiets down <br />
          when you’re in the room.{" "}
        </>
      ),
      emphasis: (
        <>
          She’s your silent partner in <br />
          cleaning the house.
        </>
      ),
    },
  },
  {
    src: housekeepingVideo,
    srcMp4: housekeepingVideoMp4,
    text: {
      paragraph: (
        <>
          She kicks into high gear <br /> when you’re not around.
        </>
      ),
      emphasis: (
        <>
          Like some kind of <br />
          stealth housekeeper.
        </>
      ),
    },
  },
  {
    src: whitenoiseMode,
    srcMp4: whitenoiseModeMp4,
    text: {
      paragraph: (
        <>
          Mila even sets up your room <br />
          for the perfect snooze.
        </>
      ),
      emphasis: (
        <>
          Let her lull you to sleep like <br />a grown-up baby.
        </>
      ),
    },
  },
];

const VideoContent = () => {
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const bodyRef = useRef(null);

  const [isVisited, setIsVisited] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [slideDuration, setSlideDuration] = useState(5460);
  const [progress, setProgress] = useState(100);

  const isInView = useLoadOnVisible(containerRef, { threshold: 0.2 });
  const isMobile = useIsMobile(769);
  useEffect(() => {
    if (!isVisited) return;
    const currentVideo = document.querySelector(
      ".video-content__slider .slick-current .video-content__video"
    );
    const allVideos = document.querySelectorAll(
      ".video-content__slider .video-content__video"
    );

    const duration = Math.ceil(currentVideo?.duration.toFixed(2));
    if (!isNaN(duration) && duration > 0) {
      setSlideDuration(duration * 1000);
    }
    allVideos.forEach((video) => {
      video.pause();
      video.currentTime = 0;
    });

    setTimeout(() => {
      currentVideo?.play();
    }, 100);
  }, [isVisited, currentVideoIndex]);

  useEffect(() => {
    if (!isVisited) return;
    if (slideDuration === 0) return;
    setProgress(100);

    const interval = setInterval(() => {
      setProgress((prev) => (prev <= 0 ? 0 : prev - 1));
    }, slideDuration * 0.01);

    return () => clearInterval(interval);
  }, [isVisited, nextIndex, slideDuration]);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: slideDuration,
    pauseOnHover: false,
    pauseOnFocus: false,
    beforeChange: (currentIdx, nextIdx) => setNextIndex(nextIdx),
    afterChange: (currentIdx) => setCurrentVideoIndex(currentIdx),
  };

  const inView = useInView(bodyRef, { threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (isVisited) return;
    setIsVisited(inView);
  }, [inView]);

  return (
    <section
      className="video-content"
      ref={containerRef}
      style={{ "--progress": `-${progress}%` }}
    >
      <div className="container">
        <div className="video-content__header">
          <motion.h2
            className="video-content__title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            And she’s quite the thoughtful houseguest.
          </motion.h2>
          <motion.p
            className="video-content__short-intro"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.01 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            {isMobile
              ? "Mila’s never loud, annoying or in the way. You’ll basically want her to stay forever."
              : "Mila is the first air purifier to know when you’re around and adapts to your schedule and habits. You’ll basically want her to stay forever."}
          </motion.p>
        </div>
        <div ref={bodyRef}>
          <Slider
            ref={sliderRef}
            className="video-content__slider"
            {...settings}
          >
            {videoData.map(({ src, srcMp4, text }, index) => (
              <div className="video-content__slide" key={index}>
                <div className="video-content__media">
                  <video className="video-content__video" playsInline muted>
                    <source src={src} type="video/webm" />
                    <source src={srcMp4} type="video/mp4" />
                  </video>
                </div>
                <div className="video-content__info">
                  <div className="video-content__progress">
                    <svg width="150" height="150" viewBox="0 0 36 36">
                      <path
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831  a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#949494"
                        strokeWidth="2"
                      />
                      <path
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#CACACA"
                        strokeWidth="2"
                        strokeDasharray={`${progress}, 100`}
                      />
                    </svg>
                  </div>
                  <div className="video-content__text">
                    <p>{text.paragraph}</p>
                    <em>{text.emphasis}</em>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="video-content__footer-bottom footer-content">
          <h4>Fun Fact 🤓</h4>
          <p>
            Mila’s patented room presence detection boosts filtration by 35% and
            lowers peak noise exposure by 60% when you’re nearby.
          </p>
          {/* <a
            href="/performance"
            className="button button--white button--lg button--shadow-dark"
          >
            Dive into Mila's Performance
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default VideoContent;
